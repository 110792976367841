import {CircleCheckIcon} from '@eon.cz/apollo13-frontend-common';
import {RemoveCircleOutline as RemoveCircleIcon} from '@mui/icons-material';
import {CommonProps} from '@mui/material/OverridableComponent';

type Props = {
    /**
     * Předaná hodnota indikuje stav aktivní/neaktivní
     */
    active: boolean | undefined | null;
};

/**
 * Obecná komponenta zobrazuje stav aktivní/neaktivní na základě předané hodnoty
 */
export const ActiveStatus = ({active, style}: Props & CommonProps) => {
    return <>{active ? <CircleCheckIcon sx={{color: 'custom.green.main'}} style={style} /> : <RemoveCircleIcon sx={{color: 'grey.500'}} style={style} />}</>;
};
