import {AnyAction, Dispatch, PayloadAction} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {OdbernaMistaFormFilterModel, OdbernaMistaSmartMereniFilterModel, OdberneMistoDetailTabValueModel} from '../model';
import {setOmFilter, setResetOmFilter, setResetSmartMereniFilter, setSmartMereniFilterReducer, setTabValueSlice} from '../reducers';

type OdbernaMistaAction = {
    readonly setFilter: (filter: OdbernaMistaFormFilterModel) => PayloadAction<OdbernaMistaFormFilterModel>;
    readonly resetFilter: () => AnyAction;
    readonly setSmartMereniFilter: (filter: OdbernaMistaSmartMereniFilterModel) => PayloadAction<OdbernaMistaSmartMereniFilterModel>;
    readonly resetSmartMereniFilter: () => AnyAction;
    readonly setTabValue: (value: OdberneMistoDetailTabValueModel) => AnyAction;
};

export const useOdbernaMistaAction = (): OdbernaMistaAction => {
    const dispatch: Dispatch<PayloadAction<OdbernaMistaFormFilterModel | OdbernaMistaSmartMereniFilterModel> | AnyAction> = useDispatch();

    return {
        /**
         * Nastavení filtru do storu
         * @param filter
         */
        setFilter: (filter: OdbernaMistaFormFilterModel) => dispatch(setOmFilter(filter)),
        /**
         * Smazání hodnot a nastavení výchozích ze storu
         */
        resetFilter: () => dispatch(setResetOmFilter()),
        /**
         * Nastavení filtru smart měření do storu
         * @param filter
         */
        setSmartMereniFilter: (filter: OdbernaMistaSmartMereniFilterModel) => dispatch(setSmartMereniFilterReducer(filter)),
        /**
         * Smazání hodnot a nastavení výchozích pro smart měření ze storu
         */
        resetSmartMereniFilter: () => dispatch(setResetSmartMereniFilter()),
        setTabValue: (value: OdberneMistoDetailTabValueModel) => dispatch(setTabValueSlice(value)),
    };
};
