import {useMutation, useQuery} from '@apollo/client';
import {
    CircleCheckIcon,
    Div,
    IconMenu,
    IconMenuItem,
    LongCellContent,
    NotificationType,
    PagingSortingGrid,
    PagingSortingGridColumnConfig,
    PaperBlock,
    RefreshButton,
    Span,
    StyledLink,
    TooltipIcon,
    buildQueryVariables,
    isQueryToBeSkipped,
    useAddNotification,
    useMatches,
    usePagingSortingGrid,
} from '@eon.cz/apollo13-frontend-common';
import {
    Komodita,
    Mutation,
    NapetovaUroven,
    Query,
    SendReportSmlouvaOdbernehoMistaInput,
    SmlouvaOdbernehoMista,
    SmlouvaOdbernehoMistaEdge,
    SmlouvaOdbernehoMistaStav,
    SmlouvaOdbernehoMistaTypMereni,
    SmlouvyOdbernychMistConnection,
    SmlouvyOdbernychMistOrderBy,
    SmlouvyOdbernychMistOrderColumns,
    TypSendReportSmlouvaOdbernehoMista,
    Ucet,
    UcetSkupinaTyp,
} from '@eon.cz/apollo13-graphql';
import {
    AccessTime as AccessTimeIcon,
    DeviceHub as DeviceHubIcon,
    ImportExportOutlined,
    InsertChart as InsertChartIcon,
    MoreVert as MoreVertIcon,
    NotificationsOutlined,
    RemoveCircleOutline as RemoveCircleIcon,
    TableViewOutlined,
    TimelapseOutlined,
    ViewAgendaOutlined,
} from '@mui/icons-material';
import {Button, IconButton, LinearProgress, TableCell, TableRow, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {isWithinInterval, startOfDay} from 'date-fns';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Lang} from '../../../Lang';
import {Store} from '../../../Store';
import {AdresaTableCell} from '../../Adresa';
import {ActiveStatus, PageRoute, RouteService} from '../../Common';
import {getFromLS, useTablet} from '../../Common/services/PageService';
import {ObchodniPartnerTableCell} from '../../ObchodniPartner';
import {toApiDate} from '../../Utils';
import {useOdbernaMistaAction} from '../actions';
import {OdbernaMistaMutations, OdbernaMistaQueries} from '../graphql';
import {OdbernaMistaFormFilterModel, OdberneMistoDetailTabValueModel} from '../model';
import {OdbernaMistaService} from '../service';
import {OdbernaMistaCard} from './OdbernaMistaCard';
import {OdberneMistoReportDatDialogProps, ReportOdbernychMistFormType} from './OdberneMistoReportDatDialog';
import {OdberneMistoUpdateDialogProps, UpdateSmlouvaOdbernehoMistaInputType} from './OdberneMistoUpdateDialog';

const OdberneMistoUpdateDialog = dynamic<OdberneMistoUpdateDialogProps>(() => import('./OdberneMistoUpdateDialog').then((mod) => mod.OdberneMistoUpdateDialog));
const OdberneMistoReportDatDialog = dynamic<OdberneMistoReportDatDialogProps>(() =>
    import('./OdberneMistoReportDatDialog').then((mod) => mod.OdberneMistoReportDatDialog),
);

type Props = {
    readonly filter: OdbernaMistaFormFilterModel;
    readonly komodita: Komodita | null | undefined;
    readonly me: Ucet;
    readonly readOnly: boolean;
    readonly dashboard: boolean | undefined;
};

export const GRID_NAME = 'ODBERNA_MISTA_TABLE';

const useStyles = makeStyles((theme: Theme) => ({
    stateActive: {
        color: theme.palette.custom.green.main,
    },
    stateFuture: {
        color: theme.palette.custom.orange.main,
    },
    stateExpired: {
        color: theme.palette.custom.brown.main,
    },

    noWrap: {
        whiteSpace: 'nowrap',
    },
}));

const createColumnConfig = (isGas: boolean, isNotObchodnik: boolean, matches: boolean) => {
    const column: Array<{columnHeader: React.ReactNode}> = [];
    column.push({
        columnHeader: isGas ? <FormattedMessage id={Lang.EIC} /> : <FormattedMessage id={Lang.EAN} />,
    });
    if (isNotObchodnik && !isGas) {
        column.push({columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_VYROBNA_EAN_VYROBNI} />});
    }

    if (isNotObchodnik) {
        column.push({
            columnHeader: (
                <TooltipIcon htmlText={<FormattedMessage id={Lang.ODBERNA_MISTA_NAZEV_TOOLTIP} />} placement="top">
                    <FormattedMessage id={Lang.ODBERNA_MISTA_NAZEV} />
                </TooltipIcon>
            ),
        });
    }
    if (!matches) {
        column.push({
            columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_ZAKAZNIK} />,
        });
    }

    column.push({
        columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_OBEC} />,
    });
    if (!matches) {
        column.push({
            columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_TYP_MERENI} />,
        });
    }
    if (!isNotObchodnik && !isGas) {
        column.push({columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_VYROBNA} />});
    }
    if (!matches) {
        column.push({
            columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_STAV_SMLOUVY} />,
        });
    }
    if (!matches) {
        column.push({
            columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_SMLUVNI_OBDOBI_OD} />,
        });
        column.push({
            columnHeader: <FormattedMessage id={Lang.ODBERNA_MISTA_SMLUVNI_OBDOBI_DO} />,
        });
    }
    column.push({
        columnHeader: '',
    });

    return column as Array<PagingSortingGridColumnConfig<SmlouvyOdbernychMistOrderColumns, SmlouvyOdbernychMistOrderBy>>;
};

/**
 * Komponenta pro přehled OM
 */
export const OdbernaMistaTable = (props: Props) => {
    const classes = useStyles({});
    const matches = useMatches();
    const isTablet = useTablet();
    const isMobile = getFromLS('mobile')?.isMobile;
    const gridProps = usePagingSortingGrid<SmlouvyOdbernychMistOrderBy[]>({
        gridName: GRID_NAME,
        initialPageSize: 10,
    });
    const [view, setView] = useState<'TABLE' | 'CARD'>(props.dashboard || matches ? 'CARD' : 'TABLE');
    const refetchStatus = useSelector((state: Store) => state.auth.refetchStatus);
    const {addNotification} = useAddNotification();
    const {setTabValue} = useOdbernaMistaAction();
    const {filter, komodita, me, readOnly, dashboard} = props;
    const skip = isQueryToBeSkipped({props: gridProps, gridName: GRID_NAME});

    const isGas = komodita === Komodita.PLYN;
    const isNotObchodnik = me?.skupina?.typ === UcetSkupinaTyp.KONCOVY_ZAKAZNIK || me?.skupina?.typ === UcetSkupinaTyp.MUNICIPALITA;

    const onlyNotObchodnikElektrina = komodita === Komodita.ELEKTRINA && isNotObchodnik;
    const onlyObchodnikElektrina = komodita === Komodita.ELEKTRINA && !isNotObchodnik;

    const queryDocument = isGas ? OdbernaMistaQueries.gql.odbernaMistaPlyn : OdbernaMistaQueries.gql.odbernaMistaElektrina;

    const mutationDocument = isGas ? OdbernaMistaMutations.gql.updateOdberneMistoNazevPlyn : OdbernaMistaMutations.gql.updateOdberneMistoNazevElektrina;
    const mutationDocumentReport = isGas ? OdbernaMistaMutations.gql.sendReportOdbernychMistPlyn : OdbernaMistaMutations.gql.sendReportOdbernychMistElektrina;

    const columnConfig = useMemo(() => createColumnConfig(isGas, isNotObchodnik, matches), [isGas, isNotObchodnik, matches]);

    const [smlouvaId, setSmlouvaId] = useState<{smlouvaId: string; nazev: string | null | undefined} | null>(null);
    const [isReportDatDialogOpen, setIsReportDatDialogOpen] = useState(false);

    const variables = {
        filter,
        showVyrobniEan: isNotObchodnik,
        callCount: isNotObchodnik,
        ...(!skip ? buildQueryVariables({props: gridProps, gridName: GRID_NAME, columnConfig}) : {}),
    };

    const {loading, data, refetch} = useQuery<Query>(queryDocument, {
        variables,
        skip: gridProps.skipPagingSortingGridQueries,
        fetchPolicy: 'network-only',
    });

    const handleRefresh = useCallback(() => refetch(), [refetch]);

    const handleCloseUpdateNazev = () => {
        setSmlouvaId(null);
    };
    const closeReportDatDialog = () => {
        setIsReportDatDialogOpen(false);
    };

    const [updateNazevMutation, {loading: updateLoading}] = useMutation<Mutation>(mutationDocument, {
        onCompleted: () => {
            handleCloseUpdateNazev();
            handleRefresh();
            addNotification({type: NotificationType.INFO, text: <FormattedMessage id={Lang.ODBERNA_MISTA_NAZEV_UPDATE_DONE} />});
        },
    });

    const [sendReportMutation, {loading: sendReportMutationLoading}] = useMutation<Mutation>(mutationDocumentReport, {
        onCompleted: () => {
            addNotification({type: NotificationType.INFO, text: <FormattedMessage id={Lang.ODBERNA_MISTA_REPORT_SUCCESS} />});

            closeReportDatDialog();
        },
        onError: () => {
            addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="komunikace" />});
        },
    });

    const smlouvyOdbernychMist =
        komodita === Komodita.ELEKTRINA ? data?.elektrina?.odbernaMista?.smlouvyOdbernychMist : data?.plyn?.odbernaMista?.smlouvyOdbernychMist;
    const materialy = data?.nastaveni?.odbernaMista?.materialyPristrojuSmartMereniElektrina;
    const showCardView = isNotObchodnik && smlouvyOdbernychMist?.edges && smlouvyOdbernychMist?.edges?.length < 6 && (matches || isTablet);
    const countOfAll =
        komodita === Komodita.ELEKTRINA
            ? data?.elektrina?.odbernaMista?.smlouvyOdbernychMist?.totalCount
            : data?.plyn?.odbernaMista?.smlouvyOdbernychMist?.totalCount;

    useEffect(() => {
        if (refetchStatus) {
            handleRefresh();
        }
    }, [handleRefresh, refetchStatus]);

    useEffect(() => {
        if (showCardView) {
            setView('CARD');
        }
    }, [showCardView]);

    if (loading || updateLoading || sendReportMutationLoading) {
        return <LinearProgress />;
    }

    if (!data) {
        return null;
    }

    const handleOnClickDetail = (id: string, type?: OdberneMistoDetailTabValueModel) => () => {
        const pathname = RouteService.getPathname(PageRoute.SMLOUVY_ODBERNYCH_MIST, '/detail', komodita);
        Router.push({pathname, query: {id}}, `${pathname}/${id}`).then(() => setTabValue(type ?? OdberneMistoDetailTabValueModel.HLAVNI));
    };

    const handleUpdateNazev = (smlouvaId: string, nazev: string | null | undefined) => () => {
        setSmlouvaId({smlouvaId, nazev});
    };

    const openReportDatDialog = () => {
        setIsReportDatDialogOpen(true);
    };

    const handleChangeView = () => setView((prevState) => (prevState === 'TABLE' ? 'CARD' : 'TABLE'));

    const onSave = (values: UpdateSmlouvaOdbernehoMistaInputType) => {
        const {smlouvaId, nazev} = values;
        updateNazevMutation({variables: {input: {nazev: nazev ?? '', smlouvaId}}});
    };

    const onReportDatSave = (values: ReportOdbernychMistFormType) => {
        const {email, datumOd, datumDo, typReportu, odbernaMistaElektrina, odbernaMistaPlyn} = values;

        const omsElektrina = odbernaMistaElektrina?.odbernaMista ?? [];
        const omsPlyn = odbernaMistaPlyn?.odbernaMista ?? [];

        // Array of EANs / EICs to send
        const eanEic = [...omsElektrina, ...omsPlyn].map((om) => om?.eanEic);

        const input = {
            email,
            eanEic: [...new Set(eanEic)],
            datumDo: typReportu === TypSendReportSmlouvaOdbernehoMista.DATA_Z_MERENI && !isGas ? toApiDate(datumDo) : undefined,
            datumOd: typReportu === TypSendReportSmlouvaOdbernehoMista.DATA_Z_MERENI && !isGas ? toApiDate(datumOd) : undefined,
            typReportu: isGas ? TypSendReportSmlouvaOdbernehoMista.TECHNICKA : typReportu,
        } as SendReportSmlouvaOdbernehoMistaInput;

        sendReportMutation({variables: {input}});
    };

    const getStateIcon = (stav: SmlouvaOdbernehoMistaStav) => {
        switch (stav) {
            case SmlouvaOdbernehoMistaStav.AKTIVNI:
                return <CircleCheckIcon className={classes.stateActive} />;
            case SmlouvaOdbernehoMistaStav.NEAKTIVNI:
                return <RemoveCircleIcon className={classes.stateExpired} />;
            case SmlouvaOdbernehoMistaStav.BUDOUCI:
                return <AccessTimeIcon className={classes.stateFuture} />;
        }
    };

    const getMenuItems = (om: SmlouvaOdbernehoMista) => {
        const showHDO = om.elektrina?.typTarifu?.klic === 'dvoutarif';
        const nizkeNapeti = om.elektrina?.napetovaUroven === NapetovaUroven.NN;
        const historieOdestuShow = om.typMereni === SmlouvaOdbernehoMistaTypMereni.B && nizkeNapeti;

        // Potřebujeme zjišťovat, zda jsou v konfiguraci materiálů pro Smart měření povolen alespoň jeden materiál.
        const aktivniMaterialyKonfigurace = materialy?.filter((m) => m.aktivni);

        const hasPristrojeWithMaterialyAktivni = om?.elektrina?.pristroje?.some((p) => aktivniMaterialyKonfigurace?.find((m) => m.klic === p?.material));

        // Smart měření zobrazujeme pouze na elektřině, pro koncové zákazníky u OM s typem měření C, kteří mají přístroj s relevatním materiálem (smartMereniAktivni),
        // a zároveň toto zobrazovat pouze pokud je z nalezených materiálů pro Smart měření alespoň jeden povolen v konfiguraci přes admin portál.
        const showSmartMereni =
            om.komodita === Komodita.ELEKTRINA &&
            om?.elektrina?.smartMereniAktivni &&
            isNotObchodnik &&
            om.typMereni === SmlouvaOdbernehoMistaTypMereni.C &&
            hasPristrojeWithMaterialyAktivni;

        const kategorieMereni =
            om?.elektrina?.kategorieMereni?.filter((item) => isWithinInterval(startOfDay(new Date()), {start: item?.platnostOd, end: item?.platnostDo}))[0]
                ?.kategorieMereni ?? '4';

        const isC123orBNN =
            (om.komodita === Komodita.ELEKTRINA && om?.typMereni === SmlouvaOdbernehoMistaTypMereni.C && kategorieMereni !== '4') ||
            (om?.typMereni === SmlouvaOdbernehoMistaTypMereni.B && om.elektrina?.napetovaUroven === NapetovaUroven.NN);

        let items: Array<IconMenuItem & {icon?: React.ReactNode; shortTitle?: string}> = [
            {
                name: 'detail',
                title: <FormattedMessage id={Lang.ODBERNA_MISTA_MENU_DETAIL} />,
                onClick: handleOnClickDetail(om.id),
            },
        ];

        if (isNotObchodnik && !readOnly) {
            items = [
                ...items,
                {
                    name: 'upravit',
                    title: <FormattedMessage id={Lang.BUTTON_UPRAVIT} />,
                    onClick: handleUpdateNazev(om.id, om?.nazev),
                },
            ];
        }
        if (isNotObchodnik && isC123orBNN) {
            items = [
                ...items,
                {
                    name: 'spotreba',
                    title: OdbernaMistaService.getLabelHistorieTypMereni('C123BNN', om.elektrina?.vyrobna ?? undefined),
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.HISTORIE_C123BNN),
                    icon: <InsertChartIcon sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        if (!isNotObchodnik && om.typMereni === SmlouvaOdbernehoMistaTypMereni.C) {
            items = [
                ...items,
                {
                    name: 'odecet',
                    title: OdbernaMistaService.getLabelHistorieTypMereni(om.typMereni),
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.HISTORIE),
                    icon: <InsertChartIcon sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        if (isNotObchodnik && !isC123orBNN) {
            items = [
                ...items,
                {
                    name: 'odecet',
                    title: OdbernaMistaService.getLabelHistorieTypMereni(om.typMereni, om.elektrina?.vyrobna ?? undefined),
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.HISTORIE),
                    icon: <InsertChartIcon sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        if (historieOdestuShow || (isNotObchodnik && isC123orBNN)) {
            items = [
                ...items,
                {
                    name: 'odecet',
                    title: OdbernaMistaService.getLabelHistorieTypMereni('C'),
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.HISTORIE_ODECTU_B),
                    icon: <InsertChartIcon sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        if (showSmartMereni) {
            items = [
                ...items,
                {
                    name: 'smart',
                    title: <FormattedMessage id={Lang.ODBERNA_MISTA_SMART_MERENI} />,
                    shortTitle: 'Smart měření',
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.SMART_MERENI),
                    icon: <DeviceHubIcon sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        if (showHDO) {
            items = [
                ...items,
                {
                    name: 'hdo',
                    title: <FormattedMessage id={Lang.ODBERNA_MISTA_HDO} />,
                    shortTitle: 'HDO',
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.HDO),
                    icon: <TimelapseOutlined sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        if (isMobile && om.komodita === Komodita.ELEKTRINA) {
            items = [
                ...items,
                {
                    name: 'notifikace',
                    title: <FormattedMessage id={Lang.UCET_NOTIFIKACE} />,
                    shortTitle: 'Notifikace',
                    onClick: handleOnClickDetail(om.id, OdberneMistoDetailTabValueModel.NOTIFIKACE),
                    icon: <NotificationsOutlined sx={{fontSize: '1.9rem'}} />,
                },
            ];
        }
        return items;
    };

    return (
        <>
            {dashboard && matches && showCardView ? (
                <Div>
                    {showCardView &&
                        view === 'CARD' &&
                        smlouvyOdbernychMist?.edges.map((om) => {
                            return (
                                <Div key={om.node.id} sx={{pb: 1, pt: 1}}>
                                    <OdbernaMistaCard
                                        om={om.node}
                                        komodita={komodita}
                                        menu={<IconMenu iconComponent={<MoreVertIcon />} items={getMenuItems(om.node)} />}
                                    />
                                </Div>
                            );
                        })}
                </Div>
            ) : (
                <PaperBlock
                    titleBlock={Lang.ODBERNA_MISTA_TITLE}
                    topLineColor={isNotObchodnik ? undefined : 'grey'}
                    titleRightBlock={
                        <Span sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
                            {isNotObchodnik && (
                                <TooltipIcon htmlText={<FormattedMessage id={Lang.ODBERNA_MISTA_VYGENEROVAT_REPORT_BUTTON_TOOLTIP} />} withButton>
                                    <Button onClick={openReportDatDialog} sx={{display: 'flex', width: 'max-content'}}>
                                        <ImportExportOutlined />
                                        <FormattedMessage id={Lang.ODBERNA_MISTA_VYGENEROVAT_REPORT_BUTTON} />
                                    </Button>
                                </TooltipIcon>
                            )}

                            <RefreshButton onClick={handleRefresh} sx={{width: '90%'}} />
                        </Span>
                    }
                    alternateButton={
                        showCardView ? (
                            <IconButton onClick={handleChangeView}>{view === 'TABLE' ? <ViewAgendaOutlined /> : <TableViewOutlined />}</IconButton>
                        ) : undefined
                    }
                    sx={{overflowX: 'auto', backgroundColor: (matches || isTablet) && showCardView && view === 'CARD' ? 'transparent' : undefined}}
                    elevation={0}
                    noPadding={!matches}
                    defaultBackground={matches && showCardView && view === 'CARD'}
                    showDivider={matches && showCardView && view !== 'CARD'}
                >
                    {showCardView &&
                        view === 'CARD' &&
                        smlouvyOdbernychMist.edges.map((om) => {
                            return (
                                <Div key={om.node.id} sx={{pb: 1, pt: 1}}>
                                    <OdbernaMistaCard
                                        om={om.node}
                                        komodita={komodita}
                                        menu={<IconMenu iconComponent={<MoreVertIcon />} items={getMenuItems(om.node)} />}
                                    />
                                </Div>
                            );
                        })}
                    {(!showCardView || view === 'TABLE') && (
                        <PagingSortingGrid
                            columnConfig={columnConfig}
                            gridName={GRID_NAME}
                            connection={smlouvyOdbernychMist as SmlouvyOdbernychMistConnection}
                            countOfAll={isNotObchodnik ? countOfAll ?? 0 : undefined}
                            hideLabelDisplayedRows={!isNotObchodnik}
                        >
                            {({node}: SmlouvaOdbernehoMistaEdge) => (
                                <TableRow key={node.id}>
                                    <TableCell size="small">
                                        <StyledLink
                                            href={{
                                                pathname: RouteService.getPathname(PageRoute.SMLOUVY_ODBERNYCH_MIST, '/detail', komodita),
                                                query: {id: node.id},
                                            }}
                                            asVariables={node.id}
                                        >
                                            {isGas ? node?.plyn?.eic : node?.elektrina?.eanSpotrebni}
                                        </StyledLink>
                                    </TableCell>
                                    {onlyNotObchodnikElektrina && <TableCell size="small">{node?.elektrina?.eanVyrobni}</TableCell>}
                                    {isNotObchodnik && (
                                        <TableCell size="small" style={{width: 200}}>
                                            <LongCellContent content={node.nazev} />
                                        </TableCell>
                                    )}
                                    {!matches && <ObchodniPartnerTableCell size="small" obchodniPartner={node.zakaznik} />}
                                    <AdresaTableCell size="small" adresa={isGas ? node?.plyn?.adresa : node?.elektrina?.adresa} short />
                                    {!matches && <TableCell size="small">{node.typMereni}</TableCell>}
                                    {onlyObchodnikElektrina && (
                                        <TableCell size="small">
                                            <ActiveStatus active={node?.elektrina?.vyrobna} />
                                        </TableCell>
                                    )}
                                    {!matches && (
                                        <>
                                            <TableCell size="small">{getStateIcon(node.stav)}</TableCell>
                                            <TableCell size="small">
                                                <FormattedDate value={node.datumOd} />
                                            </TableCell>
                                            <TableCell size="small" className={classes.noWrap}>
                                                {!!node.datumDo && <FormattedDate value={node.datumDo} />}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell size="small" className={classes.noWrap}>
                                        <IconMenu iconComponent={<MoreVertIcon />} items={getMenuItems(node)} />
                                    </TableCell>
                                </TableRow>
                            )}
                        </PagingSortingGrid>
                    )}
                </PaperBlock>
            )}

            {smlouvaId && <OdberneMistoUpdateDialog onClose={handleCloseUpdateNazev} onSave={onSave} initialValues={smlouvaId} />}

            {isReportDatDialogOpen && (
                <OdberneMistoReportDatDialog
                    onClose={closeReportDatDialog}
                    onSave={onReportDatSave}
                    komodita={komodita}
                    initialValues={{
                        email: '',
                        typReportu: TypSendReportSmlouvaOdbernehoMista.TECHNICKA,
                        omElektrinaVse: komodita === Komodita.ELEKTRINA ? !!me?.skupina?.elektrina : false,
                        omPlynVse: komodita === Komodita.PLYN ? !!me?.skupina?.plyn : false,
                    }}
                />
            )}
        </>
    );
};
