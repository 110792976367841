import {useGridActions, useMatches} from '@eon.cz/apollo13-frontend-common';
import {Ucet, UcetSkupinaTyp} from '@eon.cz/apollo13-graphql';
import {FilterAltOffOutlined, FilterAltOutlined} from '@mui/icons-material';
import {Grid, IconButton} from '@mui/material';
import {FC, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {Store} from '../../../Store';
import {KomoditaContext} from '../../Common/contexts/KomoditaContext';
import {PageContext} from '../../Common/contexts/PageContext';
import {toApiDate} from '../../Utils';
import {useOdbernaMistaAction} from '../actions';
import {OdbernaMistaFiltration} from '../components/OdbernaMistaFiltration';
import {GRID_NAME, OdbernaMistaTable} from '../components/OdbernaMistaTable';
import {OdbernaMistaFormFilterModel} from '../model';
import {initialState} from '../reducers/OdbernaMistaReducer';
import {OdbernaMistaService} from '../service';

type Props = {
    readonly dashboard?: boolean;
};
/**
 * Komponenta pro zobrazení přehledu odběrných míst s filtrací
 */
export const OdbernaMistaIndexContainer: FC<Props> = ({dashboard}) => {
    const matches = useMatches();

    const filter = useSelector((state: Store) => state.odbernaMista.filter);
    const {
        me,
        adminContext: {readOnly},
    } = useContext(PageContext);
    const isObchodnik = me?.skupina?.typ === UcetSkupinaTyp.OBCHODNIK;

    const [openFilter, setOpenFilter] = useState(() => isObchodnik || !matches);
    const {komodita} = useContext(KomoditaContext);
    const {goFirstPage} = useGridActions();
    const {resetFilter, setFilter} = useOdbernaMistaAction();

    const handleOnSubmitFilter = ({eicEanSpotrebni, datumDo, datumOd, stav, adresa, zakaznik, typMereni, nazev}: OdbernaMistaFormFilterModel) => {
        const values = {
            eicEanSpotrebni,
            datumOd: toApiDate(datumOd),
            datumDo: toApiDate(datumDo),
            stav,
            adresa,
            zakaznik,
            typMereni,
            nazev,
        };
        setFilter(values);
        goFirstPage(GRID_NAME);
    };

    const handleResetFilter = () => {
        resetFilter();
    };

    const handleOpenFilter = () => setOpenFilter((prev) => !prev);
    return (
        <Grid container spacing={1}>
            {matches && !isObchodnik && !dashboard && (
                <Grid item xs={12} textAlign="end" sx={{mr: 1}}>
                    <IconButton onClick={handleOpenFilter}>
                        {openFilter ? <FilterAltOffOutlined color="secondary" /> : <FilterAltOutlined color="secondary" />}
                    </IconButton>
                </Grid>
            )}
            {!dashboard && openFilter && (
                <Grid item xs={12}>
                    <OdbernaMistaFiltration
                        filterActive={OdbernaMistaService.isFilterActive(filter, initialState.filter)}
                        komodita={komodita}
                        initialValues={filter}
                        onSubmit={handleOnSubmitFilter}
                        onResetFilter={handleResetFilter}
                        me={me as Ucet}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <OdbernaMistaTable
                    filter={OdbernaMistaService.remapFormModelToGraphQLFilter(filter)}
                    komodita={komodita}
                    me={me as Ucet}
                    readOnly={readOnly}
                    dashboard={dashboard}
                />
            </Grid>
        </Grid>
    );
};
