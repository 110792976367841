import {ClearFilterButton, onSubmitFailFocusFieldWithError, SafeFormattedMessage, SearchButton} from '@eon.cz/apollo13-frontend-common';
import {Komodita, Ucet, UcetSkupinaTyp} from '@eon.cz/apollo13-graphql';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, MenuItem, Paper} from '@mui/material';
import * as React from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {Lang} from '../../../Lang';
import {FormInput} from '../../Common/components/form/FormInput';
import {changeUndefinedToNull} from '../../Common/services/CommonService';
import {OpositeDate} from '../../Utils/DateOperations';
import {OdbernaMistaFormFilterModel} from '../model/OdbernaMistaFormFilterModel';
import {OdbernaMistaService} from '../service/OdbernaMistaService';
import {validationSchemaOdbernaMistaFiltration} from '../service/OdbernaMistaValidation';

type Props = {
    /**
     * Whether is filter active
     */
    readonly filterActive: boolean;

    /**
     * Komodita
     */
    readonly komodita: Komodita | null | undefined;

    /**
     * Called on filter reset
     */
    readonly onResetFilter: () => void;
    readonly me: Ucet;
    readonly onSubmit: (values: OdbernaMistaFormFilterModel) => void;
    readonly initialValues: OdbernaMistaFormFilterModel;
};

/**
 * Komponenta pro filtraci OM
 */
export const OdbernaMistaFiltration: React.FC<Props> = ({initialValues, komodita, onResetFilter, filterActive, me, onSubmit}) => {
    const methods = useForm<OdbernaMistaFormFilterModel>({
        resolver: yupResolver(validationSchemaOdbernaMistaFiltration(komodita)),
        mode: 'onChange',
        defaultValues: initialValues,
        values: changeUndefinedToNull(initialValues),
        shouldUnregister: true,
    });

    const {handleSubmit, reset, control} = methods;

    const datumDo = useWatch({
        control,
        name: 'datumDo',
    });
    const datumOd = useWatch({
        control,
        name: 'datumOd',
    });

    const obchodnik = me?.skupina?.typ === UcetSkupinaTyp.OBCHODNIK;
    const isElektrina = komodita === Komodita.ELEKTRINA;

    const handleResetFilter = () => {
        reset();
        onResetFilter();
    };

    return (
        <Paper sx={{padding: 1}}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onSubmitFailFocusFieldWithError)} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput
                                name="eicEanSpotrebni"
                                type={isElektrina ? 'number' : 'text'}
                                label={komodita === Komodita.ELEKTRINA ? Lang.EAN : Lang.EIC}
                                mobileNumberOnly={isElektrina}
                                trimSpace
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput
                                name="datumOd"
                                type="dateRange"
                                datumDo={datumDo}
                                oposite={OpositeDate.DATUM_DO}
                                label={Lang.ODBERNA_MISTA_SMLUVNI_OBDOBI_OD}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput
                                type="dateRange"
                                name="datumDo"
                                oposite={OpositeDate.DATUM_OD}
                                datumOd={datumOd}
                                label={Lang.ODBERNA_MISTA_SMLUVNI_OBDOBI_DO}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput
                                name="stav"
                                type="select"
                                label={Lang.ODBERNA_MISTA_STAV}
                                customSelectComponent={OdbernaMistaService.getStavyOM().map((stav) => (
                                    <MenuItem key={stav} value={stav}>
                                        <SafeFormattedMessage
                                            langArray={Lang}
                                            langArrayKey={`ODBERNA_MISTA_STAV_${stav.toUpperCase()}`}
                                            fallbackMessage={stav}
                                        />
                                    </MenuItem>
                                ))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput name="adresa" type="text" label={Lang.ODBERNA_MISTA_ADRESA_ODBERNEHO_MISTA} />
                        </Grid>
                        {obchodnik && (
                            <Grid item xs={12} sm={3} md={4}>
                                <FormInput name="zakaznik" type="text" label={Lang.ODBERNA_MISTA_ZAKAZNIK} />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput
                                name="typMereni"
                                type="select"
                                label={Lang.ODBERNA_MISTA_TYP_MERENI}
                                customSelectComponent={OdbernaMistaService.getTypMereniOM().map((typMereni) => (
                                    <MenuItem key={typMereni} value={typMereni}>
                                        <SafeFormattedMessage langArray={Lang} langArrayKey={`${typMereni.toUpperCase()}`} fallbackMessage={typMereni} />
                                    </MenuItem>
                                ))}
                            />
                        </Grid>
                        {!obchodnik && (
                            <Grid item xs={12} sm={3} md={4}>
                                <FormInput name="nazev" type="text" label={Lang.ODBERNA_MISTA_NAZEV} />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={3} md={8} sx={{textAlign: {xs: 'center', sm: 'end'}}}>
                            <SearchButton type="submit" />
                            <ClearFilterButton onClick={handleResetFilter} disabled={!filterActive} />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </Paper>
    );
};
